
.toolbar-search{
  .submit{
    display: inline-block;
    background: #1D5D8C;
  }
}

.viuSearchLiveResults {
  .views-submit-button {
    display: none;
  }
  .view-content {
    display: none;
  }
}


// Styles for panels and panes.
// --------------------------------------------------

// Class for links in pane titles (started with mini calendar link)
.p-title-link{
  &:hover{
    text-decoration: none;
    color: #2b6b84;
  }
}

.panel-bootstrap-pane{
  @extend .clearfix;
}

.service-alternative .service {
   h4 { color:  #4C96B5; }
   i.fa {
    color: #4C96B5;
    float: left;
    padding: 10px;
    font-size: 35px;
    text-align: center;
    transition: all 0.4s ease-in-out;
   }
}

.service-alternative .service:hover {
  h4 { color:  #ffffff; }
  i.fa {
    color: #ffffff;
  }
}

.service-alternative a:hover {
  text-decoration: none;
}

// Well custom styles
@include ciel-well-style('ciel-dark-blue', $dark-blue-color, $light-blue-color);
@include ciel-well-style('ciel-light-blue', $light-blue-color, $dark-blue-color);
@include ciel-well-style('ciel-dark-green', $dark-green-color, $light-green-color);
@include ciel-well-style('ciel-light-green', $light-green-color, $dark-green-color);
@include ciel-well-style('ciel-dark-orange', $dark-orange-color, $light-orange-color);
@include ciel-well-style('ciel-light-orange', $light-orange-color, $dark-orange-color);

// Panel custom styles
@include ciel-panel-style('ciel-dark-blue', $dark-blue-color);
@include ciel-panel-style('ciel-light-blue', $light-blue-color);
@include ciel-panel-style('ciel-dark-green', $dark-green-color);
@include ciel-panel-style('ciel-light-green', $light-green-color);
@include ciel-panel-style('ciel-dark-orange', $dark-orange-color);
@include ciel-panel-style('ciel-light-orange', $light-orange-color );



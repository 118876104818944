// Styles for Views
// --------------------------------------------------


&.section-scholarship {

  #content {
    .view-section-content {
      .views-field-title {
        background-color: #F5841F;
        border: 2px solid #F37021;
      }
    }
  }

}

&.section-teaching-resources {

  #content {
    .view-section-content {
      .views-field-title {
        background-color: #4C96B5;
        border: 2px solid #005C7E;
      }
    }
  }

}

.view-about-us-pages{
  background: #003F5F;
  text-align: center;
  margin-bottom: 2em;
  .btn-u{
    color:white;
    padding-top: .75em;
    padding-bottom: .75em;
    &:before {
      content: "\f0da";
      font-family: FontAwesome;
      font-size: .75em;
      margin-right: .5em;
    }
    &:hover{
      background:#1D5D8C;
      text-decoration: none;
    }
  }
  @media(min-width: $screen-sm) {
    .view-content {
      display: flex;
      justify-content: space-around;
    }
    .btn-u {
      flex-basis: grow;
      flex-grow: 1;
      text-align: center;
    }
  }
}

//LM-66 - tighten blue boxes and line height
.view-topic-boxes{
  .service{
    min-height: 150px;
    &:hover .desc p{
      color:white;
    }
  }
  .desc p{
    line-height: 1.2;
    font-size: 13px;
    color: #555;
  }

  .service-alternative a:focus{
    text-decoration: none;
  }
  //make service boxes a bit more usable on smaller screens
  @media(min-width: $screen-sm-min) and (max-width: 1199px){
    .service-alternative{
      padding:0;
      .service{
        i.fa{
          padding:10px 0;
        }
        h4{
          font-size: 18px;
          line-height: 1.4em;
        }
      }
    }
  }

  //Equal height inside each row
  @media(min-width: $screen-sm-min) {
    .row{
      @include display-flex;
      width:100%;
      .col{
        float: none;
        @include display-flex;
        >a{
          flex-grow: 1;
          @include display-flex;
          .service{
            flex-grow:1;
          }
        }
      }
    }
  }
}

//Equal height inside each row
@media(min-width: $screen-sm-min) {
  .view-groups-feature-list {
    .views-bootstrap-grid-plugin-style {
      > .row {
        > .col-sm-4:first-child {
          padding-right: 7px;
          width: 33.6%;
        }
        > .col-sm-4:last-child {
          width: 33.6%;
          padding-left: 7px;
        }
        > .col-sm-4:not(:first-child):not(:last-child) {
          width: 32.7%;
          padding-right: 7px;
          padding-left: 7px;
        }
      }

    }
  }
}


// CW-3 : Upcoming Events View Pane - Link the date box and vertically middle align rows.
// PLEASE NOTE: stock oa_event_list view is overridden for CIEL site only

.view-display-id-oa_events_upcoming_small{
  .views-row{
    display:table;
  }
  .views-field-field-oa-date, .event-info-group{
    display: table-cell;
    vertical-align: middle;
  }
  .oa-event-date-wrapper{
    float: none;
  }
}

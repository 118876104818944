/* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 14, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container:before, .container:after {
  content: " ";
  display: table;
}

/* line 19, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    width: 728px;
  }
}

@media (min-width: 992px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    width: 948px;
  }
}

@media (min-width: 1200px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    width: 1148px;
  }
}

/* line 30, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 30, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container-fluid {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 30, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 30, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 14, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}

/* line 19, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container-fluid:after {
  clear: both;
}

/* line 39, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
.row {
  margin-left: -4px;
  margin-right: -4px;
}

/* line 14, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.row:before, .row:after {
  content: " ";
  display: table;
}

/* line 19, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.row:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 39, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }
}

@media (min-width: 992px) {
  /* line 39, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (min-width: 1200px) {
  /* line 39, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

/* line 16, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 16, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 16, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 16, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 27, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-1 {
  width: 8.33333%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-2 {
  width: 16.66667%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-3 {
  width: 25%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-4 {
  width: 33.33333%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-5 {
  width: 41.66667%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-6 {
  width: 50%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-7 {
  width: 58.33333%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-8 {
  width: 66.66667%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-9 {
  width: 75%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-10 {
  width: 83.33333%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-11 {
  width: 91.66667%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-12 {
  width: 100%;
}

/* line 55, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-0 {
  right: auto;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-1 {
  right: 8.33333%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-2 {
  right: 16.66667%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-3 {
  right: 25%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-4 {
  right: 33.33333%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-5 {
  right: 41.66667%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-6 {
  right: 50%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-7 {
  right: 58.33333%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-8 {
  right: 66.66667%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-9 {
  right: 75%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-10 {
  right: 83.33333%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-11 {
  right: 91.66667%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-12 {
  right: 100%;
}

/* line 45, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-0 {
  left: auto;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-1 {
  left: 8.33333%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-2 {
  left: 16.66667%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-3 {
  left: 25%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-4 {
  left: 33.33333%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-5 {
  left: 41.66667%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-6 {
  left: 50%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-7 {
  left: 58.33333%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-8 {
  left: 66.66667%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-9 {
  left: 75%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-10 {
  left: 83.33333%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-11 {
  left: 91.66667%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-12 {
  left: 100%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-0 {
  margin-left: 0%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-1 {
  margin-left: 8.33333%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-2 {
  margin-left: 16.66667%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-3 {
  margin-left: 25%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-4 {
  margin-left: 33.33333%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-5 {
  margin-left: 41.66667%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-6 {
  margin-left: 50%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-7 {
  margin-left: 58.33333%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-8 {
  margin-left: 66.66667%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-9 {
  margin-left: 75%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-10 {
  margin-left: 83.33333%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-11 {
  margin-left: 91.66667%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  /* line 27, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1 {
    width: 8.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-2 {
    width: 16.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-3 {
    width: 25%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-4 {
    width: 33.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-5 {
    width: 41.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-6 {
    width: 50%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-7 {
    width: 58.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-8 {
    width: 66.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-9 {
    width: 75%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-10 {
    width: 83.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-11 {
    width: 91.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-12 {
    width: 100%;
  }
  /* line 55, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-0 {
    right: auto;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-3 {
    right: 25%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-6 {
    right: 50%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-9 {
    right: 75%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-12 {
    right: 100%;
  }
  /* line 45, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-0 {
    left: auto;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-3 {
    left: 25%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-6 {
    left: 50%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-9 {
    left: 75%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-12 {
    left: 100%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  /* line 27, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-1 {
    width: 8.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-2 {
    width: 16.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-3 {
    width: 25%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-4 {
    width: 33.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-5 {
    width: 41.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-6 {
    width: 50%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-7 {
    width: 58.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-8 {
    width: 66.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-9 {
    width: 75%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-10 {
    width: 83.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-11 {
    width: 91.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-12 {
    width: 100%;
  }
  /* line 55, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-0 {
    right: auto;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-3 {
    right: 25%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-6 {
    right: 50%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-9 {
    right: 75%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-12 {
    right: 100%;
  }
  /* line 45, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-0 {
    left: auto;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-3 {
    left: 25%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-6 {
    left: 50%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-9 {
    left: 75%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-12 {
    left: 100%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  /* line 27, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1 {
    width: 8.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-2 {
    width: 16.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-3 {
    width: 25%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-4 {
    width: 33.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-5 {
    width: 41.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-6 {
    width: 50%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-7 {
    width: 58.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-8 {
    width: 66.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-9 {
    width: 75%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-10 {
    width: 83.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-11 {
    width: 91.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-12 {
    width: 100%;
  }
  /* line 55, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-0 {
    right: auto;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-3 {
    right: 25%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-6 {
    right: 50%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-9 {
    right: 75%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-12 {
    right: 100%;
  }
  /* line 45, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-0 {
    left: auto;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-3 {
    left: 25%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-6 {
    left: 50%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-9 {
    left: 75%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-12 {
    left: 100%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

/* line 12, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/_grid.scss */
.container, .section-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 12, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 12, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 12, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 14, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container:before, .container:after, .section-container:before, .section-container:after {
  content: " ";
  display: table;
}

/* line 19, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container:after, .section-container:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 12, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 728px;
  }
}

@media (min-width: 992px) {
  /* line 12, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 948px;
  }
}

@media (min-width: 1200px) {
  /* line 12, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 1148px;
  }
}

@media (min-width: 1300px) {
  /* line 12, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 1270px;
  }
}

@media (min-width: 1400px) {
  /* line 12, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 1370px;
  }
}

/* line 38, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/_grid.scss */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 38, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/_grid.scss */
  .container-fluid {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 38, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/_grid.scss */
  .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 38, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/_grid.scss */
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 14, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}

/* line 19, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container-fluid:after {
  clear: both;
}

/* line 47, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/_grid.scss */
.row {
  margin-left: -4px;
  margin-right: -4px;
}

/* line 14, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.row:before, .row:after {
  content: " ";
  display: table;
}

/* line 19, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.row:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 47, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/_grid.scss */
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }
}

@media (min-width: 992px) {
  /* line 47, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/_grid.scss */
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (min-width: 1200px) {
  /* line 47, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/_grid.scss */
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

/* line 16, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 16, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 16, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 16, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 27, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-1 {
  width: 8.33333%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-2 {
  width: 16.66667%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-3 {
  width: 25%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-4 {
  width: 33.33333%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-5 {
  width: 41.66667%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-6 {
  width: 50%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-7 {
  width: 58.33333%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-8 {
  width: 66.66667%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-9 {
  width: 75%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-10 {
  width: 83.33333%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-11 {
  width: 91.66667%;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-12 {
  width: 100%;
}

/* line 55, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-0 {
  right: auto;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-1 {
  right: 8.33333%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-2 {
  right: 16.66667%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-3 {
  right: 25%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-4 {
  right: 33.33333%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-5 {
  right: 41.66667%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-6 {
  right: 50%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-7 {
  right: 58.33333%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-8 {
  right: 66.66667%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-9 {
  right: 75%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-10 {
  right: 83.33333%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-11 {
  right: 91.66667%;
}

/* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-12 {
  right: 100%;
}

/* line 45, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-0 {
  left: auto;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-1 {
  left: 8.33333%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-2 {
  left: 16.66667%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-3 {
  left: 25%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-4 {
  left: 33.33333%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-5 {
  left: 41.66667%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-6 {
  left: 50%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-7 {
  left: 58.33333%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-8 {
  left: 66.66667%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-9 {
  left: 75%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-10 {
  left: 83.33333%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-11 {
  left: 91.66667%;
}

/* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-12 {
  left: 100%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-0 {
  margin-left: 0%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-1 {
  margin-left: 8.33333%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-2 {
  margin-left: 16.66667%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-3 {
  margin-left: 25%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-4 {
  margin-left: 33.33333%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-5 {
  margin-left: 41.66667%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-6 {
  margin-left: 50%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-7 {
  margin-left: 58.33333%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-8 {
  margin-left: 66.66667%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-9 {
  margin-left: 75%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-10 {
  margin-left: 83.33333%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-11 {
  margin-left: 91.66667%;
}

/* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  /* line 27, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1 {
    width: 8.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-2 {
    width: 16.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-3 {
    width: 25%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-4 {
    width: 33.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-5 {
    width: 41.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-6 {
    width: 50%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-7 {
    width: 58.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-8 {
    width: 66.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-9 {
    width: 75%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-10 {
    width: 83.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-11 {
    width: 91.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-12 {
    width: 100%;
  }
  /* line 55, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-0 {
    right: auto;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-3 {
    right: 25%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-6 {
    right: 50%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-9 {
    right: 75%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-12 {
    right: 100%;
  }
  /* line 45, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-0 {
    left: auto;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-3 {
    left: 25%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-6 {
    left: 50%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-9 {
    left: 75%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-12 {
    left: 100%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  /* line 27, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-1 {
    width: 8.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-2 {
    width: 16.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-3 {
    width: 25%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-4 {
    width: 33.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-5 {
    width: 41.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-6 {
    width: 50%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-7 {
    width: 58.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-8 {
    width: 66.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-9 {
    width: 75%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-10 {
    width: 83.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-11 {
    width: 91.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-12 {
    width: 100%;
  }
  /* line 55, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-0 {
    right: auto;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-3 {
    right: 25%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-6 {
    right: 50%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-9 {
    right: 75%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-12 {
    right: 100%;
  }
  /* line 45, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-0 {
    left: auto;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-3 {
    left: 25%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-6 {
    left: 50%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-9 {
    left: 75%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-12 {
    left: 100%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  /* line 27, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1 {
    width: 8.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-2 {
    width: 16.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-3 {
    width: 25%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-4 {
    width: 33.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-5 {
    width: 41.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-6 {
    width: 50%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-7 {
    width: 58.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-8 {
    width: 66.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-9 {
    width: 75%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-10 {
    width: 83.33333%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-11 {
    width: 91.66667%;
  }
  /* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-12 {
    width: 100%;
  }
  /* line 55, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-0 {
    right: auto;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-3 {
    right: 25%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-6 {
    right: 50%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-9 {
    right: 75%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-12 {
    right: 100%;
  }
  /* line 45, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-0 {
    left: auto;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-3 {
    left: 25%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-6 {
    left: 50%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-9 {
    left: 75%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-12 {
    left: 100%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

/* line 17, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.clearfix:after, .ciel-theme .panel-bootstrap-pane:after {
  content: "";
  display: table;
  clear: both;
}

/* line 54, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/viu_site_theme.style.scss */
.ciel-theme {
  /*
.col-container > div {
    max-width: 20%;

}
*/
}

/* line 6, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_panel.scss */
.ciel-theme .p-title-link:hover {
  text-decoration: none;
  color: #2b6b84;
}

/* line 17, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_panel.scss */
.ciel-theme .service-alternative .service h4 {
  color: #4C96B5;
}

/* line 18, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_panel.scss */
.ciel-theme .service-alternative .service i.fa {
  color: #4C96B5;
  float: left;
  padding: 10px;
  font-size: 35px;
  text-align: center;
  transition: all 0.4s ease-in-out;
}

/* line 29, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_panel.scss */
.ciel-theme .service-alternative .service:hover h4 {
  color: #ffffff;
}

/* line 30, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_panel.scss */
.ciel-theme .service-alternative .service:hover i.fa {
  color: #ffffff;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_panel.scss */
.ciel-theme .service-alternative a:hover {
  text-decoration: none;
}

/* line 25, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-blue {
  background-color: #005c7e;
  color: #ffffff;
  border-color: #4c96b5;
}

/* line 30, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-blue h1, .ciel-theme .well-ciel-dark-blue h2, .ciel-theme .well-ciel-dark-blue h3, .ciel-theme .well-ciel-dark-blue h4, .ciel-theme .well-ciel-dark-blue h5, .ciel-theme .well-ciel-dark-blue h6, .ciel-theme .well-ciel-dark-blue a, .ciel-theme .well-ciel-dark-blue p, .ciel-theme .well-ciel-dark-blue i {
  color: #ffffff;
}

/* line 36, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-blue h1:first-child, .ciel-theme .well-ciel-dark-blue h2:first-child, .ciel-theme .well-ciel-dark-blue h3:first-child, .ciel-theme .well-ciel-dark-blue h4:first-child {
  margin-top: 10px;
}

/* line 39, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-blue h1 a, .ciel-theme .well-ciel-dark-blue h2 a, .ciel-theme .well-ciel-dark-blue h3 a, .ciel-theme .well-ciel-dark-blue h4 a {
  background-color: transparent;
}

/* line 42, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-blue h1 a:hover, .ciel-theme .well-ciel-dark-blue h2 a:hover, .ciel-theme .well-ciel-dark-blue h3 a:hover, .ciel-theme .well-ciel-dark-blue h4 a:hover {
  text-decoration: underline;
  color: #ffffff;
}

/* line 48, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-blue a {
  text-decoration: none;
  text-decoration: underline;
  transition: all 0.1s ease-in-out;
}

/* line 53, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-blue a:hover {
  background-color: #00374b;
}

/* line 25, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-blue {
  background-color: #4c96b5;
  color: #ffffff;
  border-color: #005c7e;
}

/* line 30, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-blue h1, .ciel-theme .well-ciel-light-blue h2, .ciel-theme .well-ciel-light-blue h3, .ciel-theme .well-ciel-light-blue h4, .ciel-theme .well-ciel-light-blue h5, .ciel-theme .well-ciel-light-blue h6, .ciel-theme .well-ciel-light-blue a, .ciel-theme .well-ciel-light-blue p, .ciel-theme .well-ciel-light-blue i {
  color: #ffffff;
}

/* line 36, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-blue h1:first-child, .ciel-theme .well-ciel-light-blue h2:first-child, .ciel-theme .well-ciel-light-blue h3:first-child, .ciel-theme .well-ciel-light-blue h4:first-child {
  margin-top: 10px;
}

/* line 39, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-blue h1 a, .ciel-theme .well-ciel-light-blue h2 a, .ciel-theme .well-ciel-light-blue h3 a, .ciel-theme .well-ciel-light-blue h4 a {
  background-color: transparent;
}

/* line 42, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-blue h1 a:hover, .ciel-theme .well-ciel-light-blue h2 a:hover, .ciel-theme .well-ciel-light-blue h3 a:hover, .ciel-theme .well-ciel-light-blue h4 a:hover {
  text-decoration: underline;
  color: #ffffff;
}

/* line 48, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-blue a {
  text-decoration: none;
  text-decoration: underline;
  transition: all 0.1s ease-in-out;
}

/* line 53, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-blue a:hover {
  background-color: #3c7992;
}

/* line 25, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-green {
  background-color: #54a43d;
  color: #ffffff;
  border-color: #7fb539;
}

/* line 30, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-green h1, .ciel-theme .well-ciel-dark-green h2, .ciel-theme .well-ciel-dark-green h3, .ciel-theme .well-ciel-dark-green h4, .ciel-theme .well-ciel-dark-green h5, .ciel-theme .well-ciel-dark-green h6, .ciel-theme .well-ciel-dark-green a, .ciel-theme .well-ciel-dark-green p, .ciel-theme .well-ciel-dark-green i {
  color: #ffffff;
}

/* line 36, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-green h1:first-child, .ciel-theme .well-ciel-dark-green h2:first-child, .ciel-theme .well-ciel-dark-green h3:first-child, .ciel-theme .well-ciel-dark-green h4:first-child {
  margin-top: 10px;
}

/* line 39, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-green h1 a, .ciel-theme .well-ciel-dark-green h2 a, .ciel-theme .well-ciel-dark-green h3 a, .ciel-theme .well-ciel-dark-green h4 a {
  background-color: transparent;
}

/* line 42, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-green h1 a:hover, .ciel-theme .well-ciel-dark-green h2 a:hover, .ciel-theme .well-ciel-dark-green h3 a:hover, .ciel-theme .well-ciel-dark-green h4 a:hover {
  text-decoration: underline;
  color: #ffffff;
}

/* line 48, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-green a {
  text-decoration: none;
  text-decoration: underline;
  transition: all 0.1s ease-in-out;
}

/* line 53, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-green a:hover {
  background-color: #417f2f;
}

/* line 25, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-green {
  background-color: #7fb539;
  color: #ffffff;
  border-color: #54a43d;
}

/* line 30, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-green h1, .ciel-theme .well-ciel-light-green h2, .ciel-theme .well-ciel-light-green h3, .ciel-theme .well-ciel-light-green h4, .ciel-theme .well-ciel-light-green h5, .ciel-theme .well-ciel-light-green h6, .ciel-theme .well-ciel-light-green a, .ciel-theme .well-ciel-light-green p, .ciel-theme .well-ciel-light-green i {
  color: #ffffff;
}

/* line 36, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-green h1:first-child, .ciel-theme .well-ciel-light-green h2:first-child, .ciel-theme .well-ciel-light-green h3:first-child, .ciel-theme .well-ciel-light-green h4:first-child {
  margin-top: 10px;
}

/* line 39, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-green h1 a, .ciel-theme .well-ciel-light-green h2 a, .ciel-theme .well-ciel-light-green h3 a, .ciel-theme .well-ciel-light-green h4 a {
  background-color: transparent;
}

/* line 42, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-green h1 a:hover, .ciel-theme .well-ciel-light-green h2 a:hover, .ciel-theme .well-ciel-light-green h3 a:hover, .ciel-theme .well-ciel-light-green h4 a:hover {
  text-decoration: underline;
  color: #ffffff;
}

/* line 48, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-green a {
  text-decoration: none;
  text-decoration: underline;
  transition: all 0.1s ease-in-out;
}

/* line 53, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-green a:hover {
  background-color: #648e2d;
}

/* line 25, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-orange {
  background-color: #f37021;
  color: #ffffff;
  border-color: #f5841f;
}

/* line 30, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-orange h1, .ciel-theme .well-ciel-dark-orange h2, .ciel-theme .well-ciel-dark-orange h3, .ciel-theme .well-ciel-dark-orange h4, .ciel-theme .well-ciel-dark-orange h5, .ciel-theme .well-ciel-dark-orange h6, .ciel-theme .well-ciel-dark-orange a, .ciel-theme .well-ciel-dark-orange p, .ciel-theme .well-ciel-dark-orange i {
  color: #ffffff;
}

/* line 36, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-orange h1:first-child, .ciel-theme .well-ciel-dark-orange h2:first-child, .ciel-theme .well-ciel-dark-orange h3:first-child, .ciel-theme .well-ciel-dark-orange h4:first-child {
  margin-top: 10px;
}

/* line 39, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-orange h1 a, .ciel-theme .well-ciel-dark-orange h2 a, .ciel-theme .well-ciel-dark-orange h3 a, .ciel-theme .well-ciel-dark-orange h4 a {
  background-color: transparent;
}

/* line 42, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-orange h1 a:hover, .ciel-theme .well-ciel-dark-orange h2 a:hover, .ciel-theme .well-ciel-dark-orange h3 a:hover, .ciel-theme .well-ciel-dark-orange h4 a:hover {
  text-decoration: underline;
  color: #ffffff;
}

/* line 48, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-orange a {
  text-decoration: none;
  text-decoration: underline;
  transition: all 0.1s ease-in-out;
}

/* line 53, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-dark-orange a:hover {
  background-color: #d5580c;
}

/* line 25, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-orange {
  background-color: #f5841f;
  color: #ffffff;
  border-color: #f37021;
}

/* line 30, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-orange h1, .ciel-theme .well-ciel-light-orange h2, .ciel-theme .well-ciel-light-orange h3, .ciel-theme .well-ciel-light-orange h4, .ciel-theme .well-ciel-light-orange h5, .ciel-theme .well-ciel-light-orange h6, .ciel-theme .well-ciel-light-orange a, .ciel-theme .well-ciel-light-orange p, .ciel-theme .well-ciel-light-orange i {
  color: #ffffff;
}

/* line 36, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-orange h1:first-child, .ciel-theme .well-ciel-light-orange h2:first-child, .ciel-theme .well-ciel-light-orange h3:first-child, .ciel-theme .well-ciel-light-orange h4:first-child {
  margin-top: 10px;
}

/* line 39, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-orange h1 a, .ciel-theme .well-ciel-light-orange h2 a, .ciel-theme .well-ciel-light-orange h3 a, .ciel-theme .well-ciel-light-orange h4 a {
  background-color: transparent;
}

/* line 42, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-orange h1 a:hover, .ciel-theme .well-ciel-light-orange h2 a:hover, .ciel-theme .well-ciel-light-orange h3 a:hover, .ciel-theme .well-ciel-light-orange h4 a:hover {
  text-decoration: underline;
  color: #ffffff;
}

/* line 48, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-orange a {
  text-decoration: none;
  text-decoration: underline;
  transition: all 0.1s ease-in-out;
}

/* line 53, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .well-ciel-light-orange a:hover {
  background-color: #d76b0a;
}

/* line 62, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .panel-ciel-dark-blue {
  border-color: #005c7e;
}

/* line 65, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .panel-ciel-dark-blue > .panel-heading {
  background: #005c7e;
}

/* line 62, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .panel-ciel-light-blue {
  border-color: #4c96b5;
}

/* line 65, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .panel-ciel-light-blue > .panel-heading {
  background: #4c96b5;
}

/* line 62, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .panel-ciel-dark-green {
  border-color: #54a43d;
}

/* line 65, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .panel-ciel-dark-green > .panel-heading {
  background: #54a43d;
}

/* line 62, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .panel-ciel-light-green {
  border-color: #7fb539;
}

/* line 65, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .panel-ciel-light-green > .panel-heading {
  background: #7fb539;
}

/* line 62, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .panel-ciel-dark-orange {
  border-color: #f37021;
}

/* line 65, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .panel-ciel-dark-orange > .panel-heading {
  background: #f37021;
}

/* line 62, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .panel-ciel-light-orange {
  border-color: #f5841f;
}

/* line 65, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme .panel-ciel-light-orange > .panel-heading {
  background: #f5841f;
}

/* line 3, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_search.scss */
.ciel-theme .toolbar-search .submit {
  display: inline-block;
  background: #1D5D8C;
}

/* line 10, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_search.scss */
.ciel-theme .viuSearchLiveResults .views-submit-button {
  display: none;
}

/* line 13, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_search.scss */
.ciel-theme .viuSearchLiveResults .view-content {
  display: none;
}

/* line 77, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme.s-learning-technologies-innovation #main .headline h2, .ciel-theme.s-learning-technologies-innovation #main .headline h3, .ciel-theme.s-learning-technologies-innovation #main .headline h4 {
  border-bottom-color: #7FB539;
}

/* line 84, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme.s-learning-technologies-innovation .service-alternative .service i.fa {
  color: #7FB539;
}

/* line 87, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme.s-learning-technologies-innovation .service-alternative .service h4 {
  color: #7FB539;
}

/* line 91, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme.s-learning-technologies-innovation .service-alternative .service:hover {
  background: #7FB539;
}

/* line 93, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme.s-learning-technologies-innovation .service-alternative .service:hover h4 {
  color: #ffffff;
}

/* line 94, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme.s-learning-technologies-innovation .service-alternative .service:hover i.fa {
  color: #ffffff;
}

/* line 102, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-learning-technologies-innovation .well {
  background-color: #7FB539;
  border-color: #7FB539;
  color: #ffffff;
}

/* line 107, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-learning-technologies-innovation .well h3 {
  line-height: 22px;
  margin: 0 -20px 0 -20px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 30px;
  padding-right: 20px;
  transition: all 0.3s ease-in-out;
  font-size: 17px;
}

/* line 117, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-learning-technologies-innovation .well h3 a {
  text-decoration: none;
}

/* line 121, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-learning-technologies-innovation .well h3:hover {
  background-color: #54A43D;
}

/* line 127, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-learning-technologies-innovation .well .list-group {
  margin: 0 -20px 0 -20px;
}

/* line 130, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-learning-technologies-innovation .well a, .ciel-theme #feat-learning-technologies-innovation .well li {
  color: #ffffff;
}

/* line 134, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-learning-technologies-innovation .well .list-group-item {
  border: none;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 30px;
  line-height: 1.3;
  transition: all 0.3s ease-in-out;
  background-color: #7FB539;
}

/* line 143, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-learning-technologies-innovation .well .list-group-item:hover {
  background-color: #54A43D;
}

/* line 147, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-learning-technologies-innovation .well .list-group-item > span:before {
  content: "\f0da";
  font-family: FontAwesome;
  font-size: .75em;
  margin-right: .5em;
  margin-left: -10px;
}

/* line 77, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme.s-scholarly-teaching-practice #main .headline h2, .ciel-theme.s-scholarly-teaching-practice #main .headline h3, .ciel-theme.s-scholarly-teaching-practice #main .headline h4 {
  border-bottom-color: #F5841F;
}

/* line 84, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme.s-scholarly-teaching-practice .service-alternative .service i.fa {
  color: #F5841F;
}

/* line 87, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme.s-scholarly-teaching-practice .service-alternative .service h4 {
  color: #F5841F;
}

/* line 91, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme.s-scholarly-teaching-practice .service-alternative .service:hover {
  background: #F5841F;
}

/* line 93, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme.s-scholarly-teaching-practice .service-alternative .service:hover h4 {
  color: #ffffff;
}

/* line 94, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme.s-scholarly-teaching-practice .service-alternative .service:hover i.fa {
  color: #ffffff;
}

/* line 102, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-scholarly-teaching-practice .well {
  background-color: #F5841F;
  border-color: #F5841F;
  color: #ffffff;
}

/* line 107, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-scholarly-teaching-practice .well h3 {
  line-height: 22px;
  margin: 0 -20px 0 -20px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 30px;
  padding-right: 20px;
  transition: all 0.3s ease-in-out;
  font-size: 17px;
}

/* line 117, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-scholarly-teaching-practice .well h3 a {
  text-decoration: none;
}

/* line 121, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-scholarly-teaching-practice .well h3:hover {
  background-color: #F37021;
}

/* line 127, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-scholarly-teaching-practice .well .list-group {
  margin: 0 -20px 0 -20px;
}

/* line 130, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-scholarly-teaching-practice .well a, .ciel-theme #feat-scholarly-teaching-practice .well li {
  color: #ffffff;
}

/* line 134, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-scholarly-teaching-practice .well .list-group-item {
  border: none;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 30px;
  line-height: 1.3;
  transition: all 0.3s ease-in-out;
  background-color: #F5841F;
}

/* line 143, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-scholarly-teaching-practice .well .list-group-item:hover {
  background-color: #F37021;
}

/* line 147, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-scholarly-teaching-practice .well .list-group-item > span:before {
  content: "\f0da";
  font-family: FontAwesome;
  font-size: .75em;
  margin-right: .5em;
  margin-left: -10px;
}

/* line 77, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme.s-teaching-learning-pedagogy #main .headline h2, .ciel-theme.s-teaching-learning-pedagogy #main .headline h3, .ciel-theme.s-teaching-learning-pedagogy #main .headline h4 {
  border-bottom-color: #4C96B5;
}

/* line 84, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme.s-teaching-learning-pedagogy .service-alternative .service i.fa {
  color: #4C96B5;
}

/* line 87, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme.s-teaching-learning-pedagogy .service-alternative .service h4 {
  color: #4C96B5;
}

/* line 91, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme.s-teaching-learning-pedagogy .service-alternative .service:hover {
  background: #4C96B5;
}

/* line 93, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme.s-teaching-learning-pedagogy .service-alternative .service:hover h4 {
  color: #ffffff;
}

/* line 94, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme.s-teaching-learning-pedagogy .service-alternative .service:hover i.fa {
  color: #ffffff;
}

/* line 102, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-teaching-learning-pedagogy .well {
  background-color: #4C96B5;
  border-color: #4C96B5;
  color: #ffffff;
}

/* line 107, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-teaching-learning-pedagogy .well h3 {
  line-height: 22px;
  margin: 0 -20px 0 -20px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 30px;
  padding-right: 20px;
  transition: all 0.3s ease-in-out;
  font-size: 17px;
}

/* line 117, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-teaching-learning-pedagogy .well h3 a {
  text-decoration: none;
}

/* line 121, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-teaching-learning-pedagogy .well h3:hover {
  background-color: #005C7E;
}

/* line 127, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-teaching-learning-pedagogy .well .list-group {
  margin: 0 -20px 0 -20px;
}

/* line 130, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-teaching-learning-pedagogy .well a, .ciel-theme #feat-teaching-learning-pedagogy .well li {
  color: #ffffff;
}

/* line 134, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-teaching-learning-pedagogy .well .list-group-item {
  border: none;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 30px;
  line-height: 1.3;
  transition: all 0.3s ease-in-out;
  background-color: #4C96B5;
}

/* line 143, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-teaching-learning-pedagogy .well .list-group-item:hover {
  background-color: #005C7E;
}

/* line 147, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/base/_mixins.scss */
.ciel-theme #feat-teaching-learning-pedagogy .well .list-group-item > span:before {
  content: "\f0da";
  font-family: FontAwesome;
  font-size: .75em;
  margin-right: .5em;
  margin-left: -10px;
}

/* line 1, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_structure.scss */
.ciel-theme .content-container #header {
  background-color: transparent !important;
  background-image: none;
}

/* line 6, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_structure.scss */
.ciel-theme .col-container {
  display: table;
  width: 100%;
}

/* line 10, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_structure.scss */
.ciel-theme .col {
  display: table-cell;
  padding: 10px;
  width: 20%;
}

@media only screen and (max-width: 850px) {
  /* line 24, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_structure.scss */
  .ciel-theme .col {
    display: block;
    width: 100%;
  }
}

/* line 9, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
.ciel-theme.section-scholarship #content .view-section-content .views-field-title {
  background-color: #F5841F;
  border: 2px solid #F37021;
}

/* line 22, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
.ciel-theme.section-teaching-resources #content .view-section-content .views-field-title {
  background-color: #4C96B5;
  border: 2px solid #005C7E;
}

/* line 31, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
.ciel-theme .view-about-us-pages {
  background: #003F5F;
  text-align: center;
  margin-bottom: 2em;
}

/* line 35, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
.ciel-theme .view-about-us-pages .btn-u {
  color: white;
  padding-top: .75em;
  padding-bottom: .75em;
}

/* line 39, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
.ciel-theme .view-about-us-pages .btn-u:before {
  content: "\f0da";
  font-family: FontAwesome;
  font-size: .75em;
  margin-right: .5em;
}

/* line 45, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
.ciel-theme .view-about-us-pages .btn-u:hover {
  background: #1D5D8C;
  text-decoration: none;
}

@media (min-width: 768px) {
  /* line 51, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
  .ciel-theme .view-about-us-pages .view-content {
    display: flex;
    justify-content: space-around;
  }
  /* line 55, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
  .ciel-theme .view-about-us-pages .btn-u {
    flex-basis: grow;
    flex-grow: 1;
    text-align: center;
  }
}

/* line 65, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
.ciel-theme .view-topic-boxes .service {
  min-height: 150px;
}

/* line 67, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
.ciel-theme .view-topic-boxes .service:hover .desc p {
  color: white;
}

/* line 71, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
.ciel-theme .view-topic-boxes .desc p {
  line-height: 1.2;
  font-size: 13px;
  color: #555;
}

/* line 77, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
.ciel-theme .view-topic-boxes .service-alternative a:focus {
  text-decoration: none;
}

@media (min-width: 768px) and (max-width: 1199px) {
  /* line 82, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
  .ciel-theme .view-topic-boxes .service-alternative {
    padding: 0;
  }
  /* line 85, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
  .ciel-theme .view-topic-boxes .service-alternative .service i.fa {
    padding: 10px 0;
  }
  /* line 88, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
  .ciel-theme .view-topic-boxes .service-alternative .service h4 {
    font-size: 18px;
    line-height: 1.4em;
  }
}

@media (min-width: 768px) {
  /* line 98, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
  .ciel-theme .view-topic-boxes .row {
    display: -webkit-flex;
    display: flex;
    width: 100%;
  }
  /* line 101, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
  .ciel-theme .view-topic-boxes .row .col {
    float: none;
    display: -webkit-flex;
    display: flex;
  }
  /* line 104, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
  .ciel-theme .view-topic-boxes .row .col > a {
    flex-grow: 1;
    display: -webkit-flex;
    display: flex;
  }
  /* line 107, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
  .ciel-theme .view-topic-boxes .row .col > a .service {
    flex-grow: 1;
  }
}

@media (min-width: 768px) {
  /* line 121, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
  .ciel-theme .view-groups-feature-list .views-bootstrap-grid-plugin-style > .row > .col-sm-4:first-child {
    padding-right: 7px;
    width: 33.6%;
  }
  /* line 125, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
  .ciel-theme .view-groups-feature-list .views-bootstrap-grid-plugin-style > .row > .col-sm-4:last-child {
    width: 33.6%;
    padding-left: 7px;
  }
  /* line 129, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
  .ciel-theme .view-groups-feature-list .views-bootstrap-grid-plugin-style > .row > .col-sm-4:not(:first-child):not(:last-child) {
    width: 32.7%;
    padding-right: 7px;
    padding-left: 7px;
  }
}

/* line 145, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
.ciel-theme .view-display-id-oa_events_upcoming_small .views-row {
  display: table;
}

/* line 148, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
.ciel-theme .view-display-id-oa_events_upcoming_small .views-field-field-oa-date, .ciel-theme .view-display-id-oa_events_upcoming_small .event-info-group {
  display: table-cell;
  vertical-align: middle;
}

/* line 152, /home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss */
.ciel-theme .view-display-id-oa_events_upcoming_small .oa-event-date-wrapper {
  float: none;
}

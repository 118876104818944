// @file
// SASS partials import
// -----------------------------------------------------------------------------

// Custom Variables first, they will take precedence if defined
@import "base/variables";


// Bootstrap global partials.
// -----------------------------------------------------------------------------
// Core variables and mixins
@import "bootstrap/variables";
@import "bootstrap/mixins";

//VIU Bootstrap Overrides
// CUSTOMIZED BY VIU for 3 different responsive gutter widths as dictated by Apero designs.
@import "boostrap_overrides/mixins/_grid-framework.scss";
@import "boostrap_overrides/mixins/_grid.scss";
@import "bootstrap/grid";
@import "boostrap_overrides/_grid.scss";


//@import "boostrap_overrides/_grid.scss";

// Bootswatch partial
// -----------------------------------------------------------------------------
@import "bootswatch/variables";

// Compass mixins for backward compatibility.
// -----------------------------------------------------------------------------
@import "compass";

// viu_site_theme global partials.
// -----------------------------------------------------------------------------

@import "radix/mixins";
@import "base/mixins";
@import "base/helpers";

// Bootstrap Full
// -----------------------------------------------------------------------------
//@import "bootstrap_full/bootstrap_imports";

// Bootswatch
// -----------------------------------------------------------------------------
@import "bootswatch/bootswatch";

// Radix partials, uncomment if needed.
// -----------------------------------------------------------------------------
//@import "radix/radix_imports";

// viu_site_theme partials
// -----------------------------------------------------------------------------
.ciel-theme {
  @import "base/typography";
  ;
  @import "/home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_panel.scss";
@import "/home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_search.scss";
@import "/home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_spaces.scss";
@import "/home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_structure.scss";
@import "/home/dwoods/work/sites/viu-ciel-2/sites/all/themes/viu_site_theme/scss/components/_view.scss";
;
}

.content-container #header{
  background-color:transparent !important;
  background-image:none;
}

.col-container {
  display: table;
  width: 100%;
}
.col {
  display: table-cell;
  padding: 10px;
  width: 20%;
}

/*
.col-container > div {
    max-width: 20%;

}
*/

@media only screen and (max-width: 850px) {
  .col {
    display: block;
    width: 100%;
  }
}

// Mixins
// -----------------------------------------------------------------------------

// Gradients
@mixin ctb-gradient-vertical($startColor: #555, $endColor: #333) {
  background-color: mix($startColor, $endColor, 62.5%);
  background-image: -moz-linear-gradient(top, $startColor, $endColor); // FF 3.6+
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($startColor), to($endColor)); // Safari 4+, Chrome 2+
  background-image: -webkit-linear-gradient(top, $startColor, $endColor); // Safari 5.1+, Chrome 10+
  background-image: -o-linear-gradient(top, $startColor, $endColor); // Opera 11.10
  background-image: linear-gradient(to bottom, $startColor, $endColor); // Standard, IE10
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{darken($startColor, 1%)}', endColorstr='#{darken($endColor, 1%)}', GradientType=0); // IE9 and down
}

.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin ciel-well-style($modifier_class, $background-color, $alt-color, $text-color: #ffffff) {
  .well-#{$modifier_class} {
    background-color: $background-color;
    color: $text-color;
    border-color: $alt-color;

    h1,h2,h3,h4,h5,h6,a,p,i {
      color: $text-color;
    }

    h1,h2,h3,h4 {
      // There's already a margin above the first heading when in a well.
      &:first-child {
        margin-top: 10px;
      }
      a {
        background-color: transparent;
      }
      a:hover {
        text-decoration: underline;
        color: $text-color;
      }
    }

    a {
      text-decoration: none;
      text-decoration: underline;
      transition: all 0.1s ease-in-out;
    }
    a:hover {
      background-color: darken($background-color, 10%);
    }

  }
}


@mixin ciel-panel-style($modifier_class, $background-color, $text-color: #ffffff) {
  .panel-#{$modifier_class} {
    border-color: $background-color;

    > .panel-heading {
      background: $background-color;
    }
  }
}


@mixin ciel-space-theme($body_class, $color, $alt-color) {

  &.s-#{$body_class} {

    #main {
      .headline h2, .headline h3, .headline h4 {
        border-bottom-color: $color;
      }
    }

    .service-alternative {
      .service {
        i.fa {
          color: $color;
        }
        h4 {
          color: $color;
        }
      }
      .service:hover {
        background: $color;
        h4 { color:  #ffffff; }
        i.fa {
          color: #ffffff;
        }
      }
    }

  }

  #feat-#{$body_class} .well {
    background-color: $color;
    border-color: $color;
    color: #ffffff;

    h3 {
      line-height: 22px;
      margin: 0 -20px 0 -20px;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-left: 30px;
      padding-right: 20px;
      transition: all 0.3s ease-in-out;
      font-size:17px;

      a {
        text-decoration:none;
      }

      &:hover {
        background-color: $alt-color;
      };

    }

    .list-group {
      margin: 0 -20px 0 -20px;
    }
    a, li {
      color: #ffffff;
    }

    .list-group-item {
      border: none;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-left: 30px;
      line-height: 1.3;
      transition: all 0.3s ease-in-out;
      background-color: $color;

      &:hover {
        background-color: $alt-color;
      };

      & > span:before {
        content: "\f0da";
        font-family: FontAwesome;
        font-size: .75em;
        margin-right: .5em;
        margin-left:-10px;
      }
    }
  }

}
